@import '../../../Style.scss';

    .transfer-layout {
        min-width: 100%;
        min-height: 100%;
        padding: 0;
        background-color: $app-color__background-dark;

        .col {
            padding: 0;
        }

        .col.box-balance {
            padding: 10px;
        } 

        .banner {
            padding: 15px 30px;
            background: #f6f8fb url('/img/ribbon.png') no-repeat;
            background-position: 0% 100%;

            .banner-title {
                font-size: 28px;
                color: black !important;
                font-weight: bold;
            }

            .banner-title.is-mobile {
                font-size: 24px;
            }

            .button-login {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                top: 5px;
    
                /* Niagahoster white */
                border: 2px solid #FFFFFF;
                border-radius: 100px;
                box-shadow: none;
                transition: none;
                font-size: 16px;
                font-weight: bold;
                text-transform: none;
                background: none;
            }

            .button-login.is-mobile {
                font-size: 12px;
                width: fit-content;
            }
        }

        .banner.bpjs {
            /*background: linear-gradient(51.48deg, #4B9D4E 12.02%, #277B39 113.07%);*/
            padding: 15px;
            background: #f6f8fb url('/img/ribbon.png') no-repeat;
            background-position: 0% 100%;
        }

        .banner.pln {
            /*background: linear-gradient(144.52deg, #FFEB82 -13.16%, #F3B617 155.16%);*/
            padding: 15px;
            background: #f6f8fb url('/img/ribbon.png') no-repeat;
            background-position: 0% 100%;
            .banner-header {
                .button-login {
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }

            .banner-title {
                color: #52575C;
            }
        }

        .banner-saldo {
            font-size: 15px;
            color: black !important;
            font-weight: bold;
        }

        .banner-nominal {
            font-size: 20px;
            color: black !important;
        }

        .content-layanan {
            padding: 0px 20px 30px 20px;
            background: url('/img/bg.svg') no-repeat;
            .bd-form {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 0px;
                background: #FFFFFF;
                height: max-content;
                box-shadow: 0 10px 20px 10px rgba(152,162,179,0.1),0 2px 6px 0 rgba(152,162,179,0.25);
                border-top: 1px none #D4DAE5;
                border-bottom: 1px none #D4DAE5;
                border-left: 3px solid #0274C4;

                .tab-content {
                    width: 100%;
                }

                .form-pelanggan {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 30px;
                    .form-icon {
                        width: 18px;
                        height: 18px;
                        color: $app-color__text;
                    }

                    .form-icon-btn {
                        width: 36px;
                        height: 36px;
                        color: $app-color__text;
                    }

                    .form-icon-btn.ic-mobile {
                        width: 28px;
                        height: 28px;
                    }

                    .form-label, .form-label-main {
                        padding-bottom: 10px;
                        font-weight: normal;
                        font-size: 14px;
                    }

                    .form-label-ex{
                        margin-left: 0.2em;
                    }   

                    .form-label.start {
                        align-self: start;
                    } 
                }

                .form-pelanggan.is-mobile {
                    padding: 20px;
                }

                .form-last-trx { 
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 10px;
                    border-bottom: 1px solid #E8E8E8;
                }

                .tab-form {
                    width: 100%;
                    padding: 10px;
                }

                .col-produk {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;

                    .col-prod-item {
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid $app-color__primary;
                        border-radius: 10px;
                        width: 80%;
                        height: 72px;
                        font-size: 18px;
                    }

                    .col-prod-item.token-selected {
                        border: 2px solid #FFEB82;
                        background: linear-gradient(144.52deg, #FFEB82 -13.16%, #F3B617 155.16%);
                    }
                }
            }

            .bd-form-inq {
                padding: 25px 30px;
                flex-direction: column;
                border-radius: 10px;
                background: #FFFFFF;
                box-shadow: 3px 3px 20px $app-color__border;
                height: max-content;

                .row {
                    padding: 5px;
                }

                .inq-header {
                    text-align: center;
                    font-size: 14px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #E8E8E8;
                }

                .inq-body-last {
                    border-bottom: 1px solid #E8E8E8;
                }

                .inq-end-label {
                    text-align: end;
                }

                .inq-balance {
                    border: 2px solid $app-color__primary;
                    border-radius: 100px;
                    color: $app-color__primary;
                }

                .inq-balance.not-ok {
                    border-radius: 10px;
                    padding: 5px;
                }
            }

            .row-proses {
                padding: 20px;
                margin-bottom: 20px;
                width: 100%;
            }

            .nominal-minimal {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                text-align: right;
                margin-right: 1em;
            }

            .nominal-minimal-text {
                font-weight: bold;
                font-size: 24px;
                color: $app-color__primary;
            }

            .nominal-minimal-text.is-mobile {
                font-size: 18px;
            }

            .btn-process {
                background: linear-gradient(90.83deg, #FF9F00 -40.79%, #FF7011 125.2%);
                text-transform: capitalize;
                padding: 15px;
                border-radius: 50px;
            }
            .btn-process.bpjs {
                background: linear-gradient(51.48deg, #4B9D4E 12.02%, #277B39 113.07%);
            }
            .btn-process.telkom {
                background: linear-gradient(58deg, #EF4E37 -1.68%, #C42126 98.32%);
            }
            .btn-process.pdam {
                background: linear-gradient(90.83deg, #00C6FF -40.79%, #0072FF 125.2%);
            }
            .btn-process.pulsa {
                background: linear-gradient(90.83deg, #00C6FF -40.79%, #0072FF 125.2%);
            }
            .btn-process.game {
                background: linear-gradient(52.71deg, #3D3D3D 3.69%, #1D1D1D 145.88%);
            }
        }

        .cl-list-game {
            padding: 10px;

            .ct-list-game {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 1px solid #E8E8E8;
                box-sizing: border-box;
                border-radius: 5px;
                height: 100%;
                margin: auto;

                .row-ct-list-game {
                    padding: 10px;
                }
            }

            .ct-list-game.selected-produk {
                border: 1px solid #3D9CF3;
                background: #E1F1FF;
            }
        }

        .cl-list-emoney {
            padding: 10px;

            .ct-list-emoney {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 1px solid #E8E8E8;
                box-sizing: border-box;
                border-radius: 5px;
                height: 110%;
                margin: auto;

                .row-ct-list-emoney {
                    padding: 10px;
                }
            }

            .ct-list-emoney.selected-produk {
                border: 1px solid #3D9CF3;
                background: #E1F1FF;
            }
        }
    }

    .info-box {
        color: $app-color__primary;
        border: 1px solid $app-color__primary;
        background: #E1F1FF;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
    }

    .row-provider {
        padding-right: 10%;
        padding-left: 10%;
        padding-bottom: 5%;
    }

    .subcol-label {
        text-align: center;
        font-size: 10px;
        word-break: break-all;
    }

    .icon-col-game {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .image-logo {
        display: flex;
        max-height: 48px;
        margin-left: auto;
        margin-right: auto;
    }

    .icon-col-game.produk {
        justify-content: center;
        padding: 10px;
    }

    .box-balance {
        padding: 10px;
        border: 2px solid $app-color__primary;
        border-radius: 100px;
        color: $app-color__primary;
    }

    .box-balance.not-ok {
        border-radius: 10px;
        padding: 5px;
    }

    .col-list-game {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .col-list-game.produk {
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $app-color__primary;
        border-radius: 10px;
        padding-left: 0px;
        padding-right: 0px;
        // height: 15vh;
        min-height: 6em;
        max-height: 6em;
        cursor: pointer;
    }

    .col-list-game.produk.selected-produk {
        border: 1px solid $app-color__secondary;
    }

    .col-list-game.produk.trouble {
        border: 1px solid $app-color__border;
        cursor: no-drop;
    }

    .col-list-game.pulsa {
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $app-color__primary;
        border-radius: 10px;
        padding-left: 0px;
        padding-right: 0px;
        min-height: 8em;
        max-height: 8em;
        cursor: pointer;
    }

    .col-list-game.pulsa.selected-produk {
        border: 1px solid $app-color__secondary;
    }

    .col-list-game.pulsa.trouble {
        border: 1px solid $app-color__border;
        cursor: no-drop;
    }

    .col-list-game.logo {
        // flex: 1;
        cursor: pointer;
        margin-bottom: 1em;
        // width: 70%;
        border-radius: 10px;
        // box-shadow: 3px 3px 10px $app-color__border;
    }

    .input-group-game {
        display: 'flex';
        flex-direction: row;
        align-items: center;
    }

    .col-list-game.logo.selected-produk, .col-game.text-center.selected-produk {
        background: #E1F1FF;
    }

    .produk-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70%;
    }

    .produk-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background: $app-color__primary;
    }

    .produk-footer.selected-produk {
        background: $app-color__secondary;
    }

    .produk-footer.trouble {
        background: $app-color__border;
    }

    .produk-label {
        font-weight: bold;
        font-size: 16px;
    }

    .produk-label.is-mobile {
        font-size: 14px;
    }

    .produk-sub-label-2 {
        font-size: 14px;
        word-break: break-all;
    }

    .produk-sub-label-2.is-mobile {
        font-size: 12px;
    }

    .produk-sub-label {
        font-size: 10px;
        word-break: break-all;
    }

    .produk-sub-label.is-mobile {
        font-size: 10px;
    }

    .produk-label-footer {
        font-size: 11px;
        color: #ffffff;
        font-weight: bold;
        word-break: break-all;
    }

    .produk-label-footer.is-mobile {
        font-size: 10px;
    }

    .produk-label.selected-produk {
        color: $app-color__secondary;
    }

    .produk-sub-label.selected-produk {
        color: $app-color__secondary;
    }

    .produk-sub-label-2.selected-produk {
        color: $app-color__secondary;
    }

    .produk-label.trouble {
        color: $app-color__border;
    }

    .produk-sub-label.trouble {
        color: $app-color__border;
    }

    .color-primary {
        color: $app-color__primary;
    }

    .color-secondary {
        color: $app-color__secondary;
    }

    .label-text {
        font-size: 16px;
        line-height: 20px;
    }

    .txt-28 {
        font-size: 28px;
    }

    .txt-18 {
        font-size: 18px;
    }

    .txt-16 {
        font-size: 16px;
    }

    .txt-14 {
        font-size: 14px;
    }

    .txt-12 {
        font-size: 12px;
    }

    .txt-10 {
        font-size: 10px;
    }

    .txt-normal {
        font-weight: normal;
    }

    .txt-bold {
        font-weight: bold;
    }

    .text-end {
        text-align: end;
    }

    .pd-10 {
        padding: 10px;
    }

    .mb-5 {
        margin-bottom: 5px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 700px){
        .nominal-minimal-text{
            font-size: 16px;
        }
        
        .btn-primary{
            font-size: 12px;
        }

        .form-label-main{
            font-size: 12px !important;
            padding-bottom: 5px !important;
            margin-left: 10px;
        }

        .label-text{
            font-size: 12px !important;
            margin-left: 10px;
        }
    }