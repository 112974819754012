@import '../../../Style.scss';

.laporan-layout {
    min-height: 100%;
    padding: 0;
    background-color: $app-color__background-dark;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    transition: 0.5s;

    .col {
        padding: 0;
    }

    .new-banner {
        padding: 15px 30px;
        background: #f6f8fb url('/img/ribbon.png') no-repeat;
        background-position: 0% 100%;

        .banner-title {
            font-size: 28px;
            color: #FFFFFF !important;
            font-weight: bold;
        }

        .banner-title.is-mobile {
            font-size: 24px;
        }

        .button-login {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            top: 5px;

            /* Niagahoster white */
            border: 2px solid #FFFFFF;
            border-radius: 100px;
            box-shadow: none;
            transition: none;
            font-size: 16px;
            font-weight: bold;
            text-transform: none;
            background: #0275c4;
        }

        .button-login.is-mobile {
            font-size: 12px;
            width: fit-content;
        }
                
        .row-saldo{
            min-width: 30% !important;
        }

        .banner-title {
            font-size: 28px;
            color: black !important;
            font-weight: bold;
        }

        .banner-saldo {
            font-size: 15px;
            color: black !important;
            font-weight: bold;
        }

        .banner-nominal {
            font-size: 20px;
            color: black !important;
        }
    }
    
    .pagination > li {
        list-style: none;
        height: 40px;
        padding: 10px 10px;
        margin-top: 2em;
    }

    .pagination > li:first-child {
        margin-left:0;
        border-top-left-radius:.25rem;
        border-bottom-left-radius:.25rem
    }
    
    .pagination > li:last-child{
        border-top-right-radius:.25rem;
        border-bottom-right-radius:.25rem;
    }
    
    .pagination > li {
        color:#666;
        background-color:#fff;
        border-color:#dee2e6
    }
    
    .pagination > li.active {
        z-index:3;
        color:#fff;
        background-color:#2196f3;
        border-color:#2196f3
    }
    
    .pagination > li.disabled {
        color:#666;
        pointer-events:none;
        cursor:auto;
        background-color:#fff;
        border-color:#dee2e6
    }
    
    .banner {
        display: flex;
        flex-direction: column;
        padding: 24px 36px 16px;
        position: static;
        width: 100%;
        height: 100px;
        left: 0px;
        top: 0px;
        /* BB - Blue grad */
        background: linear-gradient(90.83deg, #00C6FF -40.79%, #0072FF 125.2%);

        .banner-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            position: static;
            width: 100%;
            height: 62px;
            left: 37px;
            top: 24px;

            .button-login {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 16px 36px;
                position: static;
                width: 255px;
                right: 0px;
                top: 5px;

                border: 2px solid #FFFFFF;
                border-radius: 100px;
                box-shadow: none;
                transition: none;
                color: #FFFFFF;
                font-family: Montserrat, sans-serif;
                font-size: 16px;
                font-weight: bold;
                text-transform: none;
                background: none;
            }
        }
        
        .row-saldo{
            min-width: 30% !important;
        }

        .banner-title {
            font-size: 28px;
            color: #FFFFFF;
            font-weight: bold;
        }

        .banner-saldo {
            font-size: 15px;
            color: #FFFFFF;
            font-weight: bold;
        }

        .banner-nominal {
            font-size: 20px;
            color: #FFFFFF;
        }
    }

    .banner.laporan {
        background: linear-gradient(144.52deg, #00C6FF -13.16%, #0072FF 155.16%);

        .banner-header {
            .button-login {
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
            }
        }

        .banner-title {
            color: #FFFFFF;
        }
    }

    .content-laporan {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;

        input, .form-control {
            outline: none !important;
            box-shadow: none !important;
        }

        .layanan-body {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 0px;
            right: 0px;
            width: 100%;

            .body-form {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background: #FFFFFF;
                width: 70%;
                box-shadow: 3px 3px 20px $app-color__border;
            }

            .search-bar {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px 16px;
                border: 0.776119px solid #8E8E8E;
                box-sizing: border-box;
                height: 40px;
                border-radius: 100px;
            }

            .input-search:focus {
                border-bottom: none;
                outline: none;
            }

            .button-export {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                color: #FFFFFF;
            }

            .btn-export{
                background: linear-gradient(144.52deg, #FF9F00 -13.16%, #FF7011 155.16%);
                border-radius: 100px;
                color: #FFFFFF;
                border-color: none;
                box-shadow: none;
                font-style: normal;
                text-transform: lowercase;
            }

            .button-picker {
                background: linear-gradient(144.52deg, #616161 -13.16%, #3A3A3A 155.16%);
                border-radius: 100px;
                color: #FFFFFF;
                height: 40px;

                .row {
                    height: 100%;
                }

                .picker-border {
                    height: 100%;
                    // margin-top: -0.5em;
                    // height: 20px;
                    // padding: 0 !important;
                }

                .calendar-icon {
                    height: 100%;
                    padding: 10px;
                }

                .form-picker {
                    height: 100%;
                    color : #FFFFFF !important;
                }
            }

            .table-laporan {
                margin-top: 3em;
                background: #FFFFFF;
                box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
                border-radius: 8px;
            
                .table thead{
                    color: #FFFFFF;
                    border-top : none;
                    background: linear-gradient(90.83deg, #00C6FF -40.79%, #0072FF 125.2%);
                }

                .table thead th:first-of-type{
                    border-top-left-radius: 8px !important; 
                }

                .table thead th:last-of-type{
                    border-top-right-radius: 8px !important; 
                }

                .head-table > tr {
                    color: #FFFFFF !important;
                }

                .trx-success {
                    color: #3D9CF3;
                }

                .trx-failed {
                    color: #EC1C24;
                }

                .icon-produk{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .icon-print{
                    color: #3D9CF3;
                    height: 20px;
                }

                .mutasi-kredit{
                    color: #008234
                }

                .mutasi-debit{
                    color: #EC1C24;
                }

                .icon-mutasi {
                    width: 30px;
                    height: 30px;
                }

                .produk-mutasi{
                    margin-left: 1em;
                    margin-top: 0.3em;
                }
            }
        }

        .layanan {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 100%;

            h4 {
                color: $app-color__text--black
            }

            .layanan-grid {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                width: 100%;

                .icon-row {
                    width: 100%;
                }

                .icon-col {
                    cursor: pointer;
                    padding: 40px;
                }

                .icon-list {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .icon-label {
                    height: 10%;
                    font-family: Montserrat, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 0.2px;
                    color: $app-color__text--dark;
                    padding-top: 10px;
                }

            }
        }
    }

    .pd-0 {
        padding: 0px;
    }

    .mgbtm-5 {
        margin-bottom: 5px;
    }

    @media all and (max-width: '450px') {
        .applyBtn {
            margin-top: 10px;
        }
    }
}