@import '../../../Style.scss';

.radius {
  border-radius: 10px;
}

.reg-modal {
  height: 771px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0px;
  z-index: 1;
  background-color: #FFFFFF;

  .title {
    color: $app-color__text--dark;
    font-size: 24px;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: bold;
    text-align: center;
  }
}

.body {
  border-bottom: 0px;

  .group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .label {
    font-size: 16px;
  }

  .label-info {
    position: static;
    width: 468px;
    height: 59px;
    left: 0px;
    top: 0px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .info {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .link {
    color: $app-color__primary;
    text-decoration: underline;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: bold;
    cursor: pointer;
  }

  .close-modal {
    cursor: pointer;
  }
}

.button-modal {
  box-shadow: none;
  transition: none;

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  background: none;
  background-image: linear-gradient(#FF9F00, #FF7011);
}

.button-m-outline {
  background: transparent;
  border: $app-color__secondary 2px solid;
  border-color: $app-color__secondary;
  color: $app-color__secondary;
}

.label-title {
  font-weight: bold;
  font-size: 18px;
}

.label-bold {
  font-weight: bold;
  font-size: 16px;
  line-height: unset;
}

.label-input-text {
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  color: $app-color__primary;
}

.label-input-text.is-mobile {
  font-size: 18px;
}

.txt-18 {
  font-size: 18px;
}

.txt-16 {
  font-size: 16px;
}

.medium {
  font-size: 14px;
}

.small {
  font-size: 10px;
}

.placeholder-text {
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.15em;
}

.desc {
  margin-bottom: 20px;
  border-bottom: 2px solid $app-color__primary;
}

.desc-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
}

.tab-deposit {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.color-primary {
  color: $app-color__primary
}

.color-secondary {
  color: $app-color__secondary
}

.color-white {
  color: #FFFFFF
}

.btn:hover {
  color: #fff !important;
  text-decoration: none;
}
                 
.button-back {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #FE9923;
  color : #FE9923;
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: 2em;
  margin-right: 2em;
  width: 100%;
  height: 64px;
  box-shadow: none;
  cursor: pointer;
}
                 
.button-back.va {
  background: #FE9923;
  border: 1px solid #FE9923;
  color : #FFFFFF;
  cursor: pointer;
}

.mg-top-10 {
  margin-top: 10px;
}

.pd-top-10 {
  padding-top: 10px;
}

.pd-top-20 {
  padding-top: 20px;
}

.pd-top-30 {
  padding-top: 30px;
}

.pd-right {
  padding-right: 10px;
}