@import '../../Style.scss';

.sidebar {
  min-height: 100%;
  max-height: 100%;
  background: #ffffff;
  color: $app-color__primary;
  transition: all 0.5s;
  padding: 0px;
  position: absolute;

  .col {
      padding: 0;
  }

  .sidebar-header {
    background: #313b4c;
    color: #adb5bd;
  }

  .sidebar-header h6 {
    color: #fff;
    padding: 0.5em;
    margin: 0;
  }

  .logout {
    background: $app-color__danger;
    color: $app-color__background;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  ul p {
    color: #fff;
    padding: 10px;
  }

  .collapse.show,
  .collapsing,
  .sidebar-content {
    padding-top: $app-dimen__padding--medium;
    background: #ffffff;
    width: 100%;
    // max-height: 100vh;
    // overflow-y: auto;
    // font-size: 14px;
  }

  .sidebar-content a.parent {
    font-family: 'Montserrat-Bold', sans-serif;
    color: $app-color__text--dark;
  }

  .sidebar-content a.parent.open {
    color: $app-color__primary;
  }

  .sidebar-content .nav-item > a,
  .sidebar-content a.nav-link:not(.parent) {
    color: $app-color__text;
  }

  .sidebar-content > .nav-item:hover > a,
  .sidebar-content a.nav-link:hover,
  .sidebar-content a.nav-link.active {
    color: $app-color__primary;
    transition: 0.2s;
  }
  
  .nav-item {
    padding-bottom: 10px;
  }

  .nav-item .svg-inline--fa {
    color: inherit;
  }

  .first {
    padding-top: 10px;
  }

  .last {
    border-bottom: #E8E8E8 solid 2px;
    padding-bottom: 10px;
  }

  li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
  }

  > button {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar.is-web {
  min-width: 100%;
  max-width: 100%;
}

.sidebar.is-open .sidebar-content div.nav-item:not(.open) a.parent strong {
  color: $app-color__primary;
}

.sidebar.is-open + .mask {
  display: block;
}

.container.is-open {
  margin-left: 250px;
}

.footer-logout {
  background: $app-color__danger;
  color: #FFFFFF;
  padding: 10px;
  // bottom: 0px;
  cursor: pointer;
}

.footer-logout.is-open {
  transition: 0.5s;
  position: fixed;
  bottom: 0px;
  width: 17%;
  // position: sticky;
}

.footer-logout.is-mobile {
  width: 100%;
}
