@import '../../Style.scss';

.wrapper-promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  background-color: rgb(216, 239, 255);
  color: black;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #D4DAE5;

  .head-title {
    font-weight: bold;
    font-size: 16px;
  }

  .info-list {
    display: grid;
    gap: 10px;
    margin-block-start: 0px;
    padding-inline-start: 20px;
    margin-top: 10px;
    font-size: 14px;
  }

  .btn-term {
    cursor: pointer;
    font-weight: bold;
  }
}