$app-color__primary: #0275C4;
$app-color__secondary: #FF8F00;
$app-color__danger: #EC1C24;
$app-color__success: #4B9D4E;
$app-color__background: #ffffff;
$app-color__background-dark: #F6F8FB;
$app-color__border: #CCCCCC;
$app-color__text: #777777;
$app-color__text--dark: #52575C;
$app-color__text--black:#25282B;

$app-dimen__border-radius--medium: 4px;
$app-dimen__border-width--small: 1px;
$app-dimen__border-width--medium: 2px;
$app-dimen__padding--small: 4px;
$app-dimen__padding--medium: 8px;
$app-dimen__padding--large: 16px;
$app-dimen__scroll--medium: 8px;
$app-dimen__toolbar_height: 64px;
$app-dimen__thumb--large: 256px;
$app-dimen__thumb--medium: 144px;
$app-dimen__thumb--small: 96px;
$app-dimen__text--small: 10px;
$app-dimen__text--medium: 12px;
$app-dimen__text--large: 21px;
