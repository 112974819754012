.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    padding-top: 10px;

    header {
      margin-bottom: 1rem;
    }
    
    main {
      text-align: center;
      margin-bottom: 2rem;
    }
    
    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    
    p {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
    
    button {
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 0.8rem 1.5rem;
      font-size: 1.2rem;
      cursor: pointer;
    }
    
    button:hover {
      background-color: #0069d9;
    }
    
    footer {
      margin-top: auto;
      padding: 1rem;
      text-align: center;
      background-color: #f5f5f5;
      width: 100%;
    }
  }
  