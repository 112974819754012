@import '../../../Style.scss';

.struk-layout {
    min-height: 100%;
    padding: 0;
    background-color: $app-color__background-dark;

    .col {
        padding: 0;
    }

    .header-layout {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .button {
        background: #FFFFFF;
        border: 1px solid #FE9923;
        color: #FE9923;
        box-sizing: border-box;
        border-radius: 10px;
        height: 70px;
        box-shadow: none;
        margin-right: 2em !important;
        margin-left: 2em !important;
    }
        
    .banner-pending {
        background: linear-gradient(90.83deg, #FF9F00 -40.79%, #FF7011 125.2%) !important;
    }

    .banner-failed {
        background: linear-gradient(134.29deg, #616161 -25.01%, #3A3A3A 121.97%) !important;
    }

    .container-layout {
        height: 100%;
        width: 100%;
        transition: 0.5s;
        
        .banner-struk {
            width: 100%;
            color: #FFFFFF;
            justify-content: center;
            display:flex;
            align-items:center;
            margin: 0;
            padding: 10px 10px 80px 10px;
            background: linear-gradient(90.83deg, #00C6FF -40.79%, #0072FF 125.2%);

            .banner-head {
                justify-content: center;
            }

            .banner-ico {
                font-size: 60px;
                font-weight: bold;
                background: #3ea0ff;
                padding: 5px 10px 5px 10px;
                border-radius: 50%;
                width: 85px;
                height: 85px;
                text-align:center !important;
                vertical-align: middle;
            }

            .ico-pending {
                padding: 15px 10px 10px 10px;
                background: #ff9d23 !important;
                -ms-transform: rotate(-25deg); /* IE 9 */
                transform: rotate(-25deg);
            }

            .ico-failed {
                background: #737373 !important;
            }

            .span-info{
                font-size: 28px;
                font-weight: bold;
                text-align: center;
                margin-top: 0.5em;
            }

            .span-additional {
                font-size: 15px;
                align-items: center !important;
                text-align:center;
                margin-top: 0.5em;
                margin-bottom: 0.5em;
            }
        }

        .body-pending {
            width: 35% !important;
        }

        .body-col-pending{
            margin-bottom: 2em;
            align-items: center;
            text-align: center;
        }

        .content-laporan {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -5em;

            .layanan-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0px;
                right: 0px;
                width: 100%;

                .body-form {
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background: #FFFFFF;
                    width: 70%;
                    box-shadow: 3px 3px 20px $app-color__border;

                    .body-col {
                        padding: 10px 5px 5px 10px;
                        margin-left: 2em;
                        margin-right: 2em;
                        margin-top: 2em;
                    }

                    .detail-head{
                        color: #3D9CF3;
                        font-weight: bold;
                        font-size: 24px;
                    }

                    .border-image{
                        .image-content{
                            width: 100%;
                            border: 1px solid #DDDDDD;
                            margin-top: 10px;
                            padding: 5px 5px 5px 5px;
                            box-sizing: border-box;
                        }
                    }

                    button:hover {
                        color : #FE9923 !important;
                    }

                    .button-print{
                        width: 100%;
                        height: 56px;
                        background: linear-gradient(90.83deg, #FF9F00 -40.79%, #FF7011 125.2%);
                        border-radius: 0px 0px 10px 10px;
                        text-transform: capitalize;
                        font-size: larger;
                    }
                    
                    .button-print:hover {
                        color : #FFFFFF !important;
                    }

                    .button-pdf{
                        width: 100%;
                        height: 56px;
                        background: linear-gradient(58deg, #EF4E37 -1.68%, #C42126 98.32%);
                        border-radius: 0px 0px 10px 10px;
                        text-transform: capitalize;
                        font-size: larger;
                    }
                    
                    .button-pdf:hover {
                        color : #FFFFFF !important;
                    }

                    .border-bottom{
                        border: 1px solid #E8E8E8;
                        margin-top: 2em;
                        width: 100%;
                        margin-bottom: 2em;
                    }

                    .button-down{
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 2em;
                    }
                    
                    .list-Info{
                        margin-top: 3em;
                    }
                    
                    .detail-info {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        color: #52575C;
                    }

                    .detail-data {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        color: #52575C;
                        display: inline;
                    }

                    .info-print {
                        color:#FFFFFF !important;
                        margin-left: 1em;
                        font-weight: 600;
                    }

                    .info-print.left {
                        margin-right: 1em;
                    }

                    .info-print.print-mobile {
                        font-size: 12px;
                    }
                }   
                
                .body-form.form-mobile {
                    width: 90%;
                }
            }

            .layanan {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                width: 100%;

                h4 {
                    color: $app-color__text--black
                }

                .layanan-grid {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    width: 100%;

                    .icon-row {
                        width: 100%;
                    }

                    .icon-col {
                        cursor: pointer;
                        padding: 40px;
                    }

                    .icon-list {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    .icon-label {
                        height: 10%;
                        font-family: Montserrat, sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.2px;
                        color: $app-color__text--dark;
                        padding-top: 10px;
                    }

                }
            }
        }
    }

    .info-box {
        color: $app-color__primary;
        border: 1px solid $app-color__primary;
        background: #E1F1FF;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
    }

    .txt-22 {
        font-size: 22px;
    }

    .pd-0 {
        padding: 0;
    }
}