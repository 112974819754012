@import '../../Style.scss';

.accordion-pln {
  border-radius: 5px;
  background: #FFFFFF;
  height: max-content;
  margin-top: 40px;
  box-shadow: 0 10px 20px 10px rgba(152,162,179,0.1),0 2px 6px 0 rgba(152,162,179,0.25);
  border-top: 1px none #D4DAE5;
  border-bottom: 1px none #D4DAE5;
  padding: 0px 0px 0px 0px;

  .button-collapse {
      color: #222!important;
      font-weight: bolder;
      font-size: 12px;
  }
  .button-collapse:hover {
      color: #0275C4 !important;
      text-decoration: underline;
  }
  .button-collapse:active {
      box-shadow: none !important;
  }
  .button-collapse:not(:hover) {
      text-decoration: none !important;
  } 

  .card-header-accordion {
      background-color: rgba(0, 0 , 0, .03) !important;
  }

  .card-content {
      padding: 1em 30px 0px;
  }

  .list-content {
      display: grid !important;
      gap: 10px !important;
      margin-block-start: 0px !important;
      padding-inline-start: 20px !important;
  }
}

.content-pln {
  border-radius: 5px;
  background: #FFFFFF;
  height: max-content;
  box-shadow: 0 10px 20px 10px rgba(152,162,179,0.1),0 2px 6px 0 rgba(152,162,179,0.25);
  border-top: 1px none #D4DAE5;
  border-bottom: 1px none #D4DAE5;
  padding: 30px;
}