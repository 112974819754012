@import './Style.scss';

/* latin */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/rP2fp2a15UIB7Un-bOeISG3pHl4G9wn5.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzs74u2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/rP2Bp2a15UIB7Un-bOeISG3pHls29Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/rP2cp2a15UIB7Un-bOeISG3pFuAT4C7c7Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Italic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Italic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Montserrat-Bold';
  font-style: bold;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Montserrat-Bold';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* --font-family-sans-serif, */
body,
.tooltip,
.popover {
  font-family: 'Montserrat', sans-serif;
  font-size: .9rem;
}

::-webkit-scrollbar {
  width: $app-dimen__scroll--medium;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $app-dimen__scroll--medium rgba($app-color__primary, 0.3);
  box-shadow: inset 0 0 $app-dimen__scroll--medium rgba($app-color__primary, 0.3);
  border-radius: $app-dimen__border-radius--medium;
}

::-webkit-scrollbar-thumb {
  border-radius: $app-dimen__border-radius--medium;
  box-shadow: inset 0 0 $app-dimen__scroll--medium rgba($app-color__primary, 0.5);
  -webkit-box-shadow: inset 0 0 $app-dimen__scroll--medium rgba($app-color__primary, 0.5);
}

.row {
  margin-right: -$app-dimen__padding--medium / 2;
  margin-left: -$app-dimen__padding--medium / 2;
}

[class^='col-'] {
  padding-right: $app-dimen__padding--medium / 2;
  padding-left: $app-dimen__padding--medium / 2;
}

.full-width {
  width: 100%;
}

.app-bg {
  background-size: cover;
  background-repeat: no-repeat;
}

.col-sidebar {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.col-sidebar.is-open {
    position: fixed;
    visibility: visible;
    opacity: 1;
    z-index: 3;
}

.col-sidebar.is-mobile {
    z-index: 3;
}

.header {
    z-index: 4;
    background-color: $app-color__background-dark;
}

.jumbotron {
    margin-bottom: 1rem;
}

// .navbar-brand img,
// .media > img {
//   object-fit: contain;
// }

.pointing {
  cursor: pointer;
}

.text-bb-primary {
  color: $app-color__primary;
}

.payment-channel {
  border-radius: $app-dimen__border-radius--medium;
  border: 1px solid $app-color__border;

  p {
    margin: 0;
  }
}

.payment-code {
  font-weight: bold;
}

.code {
  white-space: pre-wrap;
  position: relative;

  .copy-button {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
  }
}

.basic-single {
  width: 90%;
  align-self: center;
  margin-left: 10px;

  .select__control {
    background: transparent;
    border: none;
    border-color: transparent;
    box-shadow: unset;
  }

  .select__indicators {
    display: none;
  }

  .select__input {
    text-decoration: none !important;
    outline: none;

    #react-select-3-input {
      box-shadow: none;
    }
  }
}

.basic-single.is-mobile {
  width: 90%;
  margin-left: 5px;
}

.header {
  position: sticky;
  top: 0;
}

.img-block {
  margin: auto;
  text-align: center;
  display: block;
}

.channel-badge {
  .badge {
    display: inherit;
  }
}

.navbar-brand {
  padding: 0;
  
  img {
    height: 36px;
  }
}

.content {
  background-color: #F6F8FB;
  min-height: calc(100vh);
}

.navbar {
  z-index: 9999;
}

.color-primary {
    color: $app-color__primary;
}

.color-secondary {
    color: $app-color__secondary;
}

.color-success {
    color: $app-color__success;
}

.color-danger {
    color: $app-color__danger;
}

.txt-bold {
    font-weight: bold;
}

.pd-10 {
    padding: 10px;
}

/*BARU*/
.sidebar .nav-item {
  padding-bottom: 10px;
  color: #2d2d2d !important;
}
.sidebar .sidebar-content .nav-item > a, .sidebar .sidebar-content a.nav-link:not(.parent){
    color: #282828 !important;
}
.home-layout .content-layanan .icon-label {
  text-align: center;
  color: #282828;
  padding-top: 10px;
}

.form-pelanggan .form-control {
  font-size: 16px;
  color: black;
  font-weight: bold;
}

.sidebar .sidebar-content > .nav-item:hover > a, .sidebar .sidebar-content a.nav-link.active, .sidebar .sidebar-content a.nav-link:hover{
  border-left: 10px solid #0174c4;
}

.grecaptcha-badge { 
  visibility: hidden;
}