@import '../../Style.scss';

.radius {
  border-radius: 10px;
}

.reg-modal {
  height: 771px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0px;
  z-index: 1;
  background-color: #FFFFFF;

  .title {
    color: $app-color__text--dark;
    font-size: 24px;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .title.t-mobile {
    font-size: 22px;
  }
}

.body {
  border-bottom: 0px;

  .card {
    box-shadow: none;
  }

  .group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .label {
    font-size: 16px;
  }

  .label-info {
    position: static;
    width: 468px;
    height: 59px;
    left: 0px;
    top: 0px;
    
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .label-bold {
    font-weight: bold;
    color: $app-color__text--dark;
  }

  .info {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .link {
    color: $app-color__primary;
    text-decoration: underline;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: bold;
    cursor: pointer;
  }

  .close-modal {
    cursor: pointer;
  }

  .medium {
    font-size: 14px;
  }

  .small {
    font-size: 10px;
  }

  .lupa-pin-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 36px;

    position: static;
    width: 320px;
    height: 52px;
    right: 110px;
    top: 325px;

    /* background: #FFFFFF; */
    border: 1px solid #8E8E8E;
    box-sizing: border-box;
    border-radius: 500px;
    box-shadow: none;
    transition: none;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #8E8E8E;
    text-transform: none;
    background: none;
  }

  .inq-end-label {
      text-align: end;
  }

  .inq-balance {
      border: 2px solid $app-color__primary;
      border-radius: 100px;
      color: $app-color__primary;
      padding: 5px;
  }

  .inq-balance.not-ok {
      border-radius: 10px;
  }

  .otp-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }

  .btn-otp {
    width: 45%;
  }
  
  .btn-otp.wa {
    background: linear-gradient(51.48deg, #4B9D4E 12.02%, #277B39 113.07%);
  }

  .btn-otp.wa:hover {
    background: linear-gradient(51.48deg, #3E9041 12.02%, #1A6E2C 113.07%);
  }
}

.button-modal {
  box-shadow: none;
  transition: none;

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  background: none;
  background-image: linear-gradient(#FF9F00, #FF7011);
}

.button-m-outline {
  background: transparent;
  border: $app-color__secondary 2px solid;
  border-color: $app-color__secondary;
  color: $app-color__secondary;
}

.cta {
  /* cta */

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 73px 0px 10px;
  position: static;
  left: 0%;
  right: 0%;
  top: 57.6%;
  bottom: 29.27%;
  border-left: $app-color__primary solid;

  /* BB - Blue lg */
  background: #E1F1FF;

  /* Font */
  font-size: 14px;
  font-weight: bold;
  color: $app-color__primary;
}

.list-info {
  position: static;
  width: 100%;
  left: 0px;

  .ls {
    display: flex;
    flex-direction: row;
    align-items: center;
    // height: 48px;

    .ls-number {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;

      .no-circle {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        color: #FFFFFF;
        background-color: $app-color__primary;
      }
    }

    .ls-content {
      margin-left: 5px;
      width: 90%;
    }
  }
}

.frm-otp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  height: 54px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  .frm-view {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .frm-txt {
      font-size: 24px;
      letter-spacing: 0.15em;
    }

    .plchd-text {
      font-size: 24px;
      letter-spacing: 0.15em;
      width: 50%;
    }

    .plchd-text.sms {
      text-align: center;
    }
  }

  .frm-view.sms {
    justify-content: center;
  }
}

.text-ket {  
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text-bold {
  font-weight: bold;
  color: $app-color__text--dark;
}

.text-bold.default {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.placeholder-text {
  font-size: 24px;
  letter-spacing: 0.15em;
}

.text-12 {
  font-size: 12px;
}

.btn:hover {
  color: #fff !important;
  text-decoration: none;
}

.mg-top-10 {
  margin-top: 10px;
}

.pd-top-10 {
  padding-top: 10px;
}

.pd-top-20 {
  padding-top: 20px;
}

.pd-top-30 {
  padding-top: 30px;
}

.pd-right {
  padding-right: 10px;
}