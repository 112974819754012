@import '../../../Style.scss';

.deposit-layout {
    min-width: 100%;
    min-height: 100%;
    padding: 0;
    background-color: $app-color__background-dark;

    .col {
        padding: 0;
    }

    .header-layout {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .side-menu {
        height: 100%;
        width: 0%;
        transition: 0.5s;
    }

    .side-menu.is-open {
        width: 13%;
        transition: 0.5s;
    }

    .content-layout {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        transition: 0.5s;

        .content-head {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: static;
            width: 100%;
            height: 198px;
            left: 0px;
            top: 0px;
            /* BB - Blue grad */
            padding: 15px 30px;
            background: #f6f8fb url('/img/ribbon.png') no-repeat;
            background-position: 0% 10%;

            .head-top {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 50%;
                color: black !important;

                .head-title {
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 34px;
                    text-align: center;
                    letter-spacing: 0.1px;
                }

                .head-sub-title {
                    margin-top: 10px;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 0.1px;
                }
            }
        }

        .content-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            // padding: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            top: -60px;
            padding: 0px 20px 30px 40px;
            background: url('/img/bg.svg') no-repeat;

            .payment-code {
                margin-top: 1.3em;
                font-weight: normal;
                padding: 10px;
                width: 100%;
            }

            .listVa {
                padding: 10px;
            }

            .body-form {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background: #FFFFFF;
                width: 70%;
                height: 100%;
                padding: 10px;
                box-shadow: 3px 3px 20px $app-color__border;

                .form-content {
                    flex-direction: column;
                    width: 93%;

                    .form-ct-top {
                        height: 20%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row;

                        .ct-top {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 50%;
                        }
                    }

                    .form-ct-body {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .ct-body-label {
                            width: 100%;
                            display: inline-block;
                            font-size: 16px;
                            text-align: center;
                            letter-spacing: 0.1px;
                            color: $app-color__text--dark;
                        }

                        .icon-row {
                            width: 80%;
                        }

                        .icon-col {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            padding: 10px;
                        }

                        .icon-list {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 135px;
                            height: 135px;
                            background: #FFFFFF;
                            border: 1px solid #DDDDDD;
                            box-sizing: border-box;
                            border-radius: 5px;
                        }

                        .icon-va {
                            width: 75%;
                            height: 135px;
                            margin-left: 2em;
                        }

                        .icon-list.selected {
                            background: #E1F1FF;
                            border: 1px solid $app-color__primary;
                        }
    
                        .icon-label {
                            font-size: 14px;
                            text-align: center;
                            color: $app-color__text--dark;
                            padding-top: 10px;
                        }
    
                        .icn-sub-label {
                            font-weight: bold;
                            color: $app-color__primary;
                            font-size: 10px;
                            margin-top: 1em;
                        }

                        .row-proses {
                            display: flex;
                            flex-direction: row;
                            padding-bottom: 20px;
                            margin-top: 20px;
                            margin-bottom: 20px;
                            border-bottom: 1px solid #E8E8E8;
                        }

                        .nominal-minimal {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: center;
                            padding-right: 10px;
                        }

                        .nominal-minimal-text {
                            font-weight: bold;
                            font-size: 28px;
                            line-height: 34px;
                            color: $app-color__primary;
                        }

                        .nominal-minimal-text.is-mobile {
                            font-size: 20px;
                        }

                        .btn-next {
                            background: linear-gradient(90.83deg, #FF9F00 -40.79%, #FF7011 125.2%);
                            border-radius: 10px;
                        }
                    }

                    .form-ct-body.tc {
                        padding-top: 20px;
                    }
                }
            }

            .body-form.is-mobile {
                width: 90%;
            }
        }
    }

    .deposit-aktif { 
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;

        .rw-body {
            border: 1px solid #DDDDDD;
            border-radius: 10px;
            align-items: center;
            padding: 10px;
            width: 100%;
            background: #F6F8FB;
        }
    }

    .toast-div {
        position: absolute;
        bottom: 10px;
        left: 0px;
        right: 0px;
        width: max-content;
        margin: auto;
        z-index: 2;

        .toast {
            color: $app-color__primary;
            border: 2px solid $app-color__primary;
            box-sizing: border-box;
            background: #E1F1FF;
            border-radius: 5px;
            padding: 5px;

            .toast-body {
                padding: 10px;
            }

            .toast-header {
                border: none;
                background: #E1F1FF;

                .close {
                    margin-bottom: 0px;
                    color: $app-color__primary;
                    display: none;
                }
            }
        }
    }

    .info-box {
        color: $app-color__primary;
        border: 1px solid $app-color__primary;
        background: #E1F1FF;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
    }

    .label-bold {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
    }

    .label-text {
        font-size: 16px;
        line-height: 20px;
    }

    .label-text.primary {
        color: $app-color__primary;
    }

    .txt-bold {
        font-weight: bold;
    }

    .txt-20 {
        font-size: 20px;
    }

    .txt-14 {
        font-size: 14px;
    }

    .txt-16 {
        font-size: 16px;
    }

    .add-space{
        margin-top: 0.7em;
    }

    .color-primary {
        color: $app-color__primary;
    }

    .color-danger {
        color: $app-color__danger;
    }

    .icon-48 {
        width: 48px;
        height: 48px;
    }
}