@import '../../Style.scss';

.home-layout {
    min-height: 100%;
    padding: 0;
    background-color: $app-color__background-dark;

    .col {
        padding: 0;
    }

    .col-sidebar {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    .col-sidebar.is-open {
        visibility: visible;
        opacity: 1;
        z-index: 3;
    }

    .col-sidebar.is-mobile {
        z-index: 3;
    }

    .header {
        z-index: 4;
        background-color: $app-color__background-dark;
    }

    .jumbotron {
        margin-bottom: 1rem;
    }

    .banner {
        padding: 15px;
        /*background: linear-gradient(90.83deg, #00C6FF -40.79%, #0072FF 125.2%);*/
        background: #f6f8fb url('/img/ribbon.png') no-repeat;

        .button-login {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            top: 5px;

            /* Niagahoster white */
            border: 2px solid #FFFFFF;
            border-radius: 100px;
            box-shadow: none;
            transition: none;
            font-size: 16px;
            font-weight: bold;
            text-transform: none;
            background: #0275c4;
        }

        .banner-title {
            font-size: 28px;
            color: black !important;
            font-weight: bold;
        }

        .banner-saldo {
            font-size: 15px;
            color: black !important;
            font-weight: bold;
        }

        .banner-nominal {
            font-size: 20px;
            color: black !important;
        }

        .banner-slider {
            /* Auto Layout */
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            position: static;
            width: 100%;
            height: 270px;
            top: 110px;

            .alice-carousel {
                height: 270px;
                padding-left: 10px;
            }

            .alice-carousel ul li img {
                top: 0px;
                object-fit: fill;
                border-radius: 10px;
            }

            .alice-carousel__dots-item {
                background: #FFFFFF;
                opacity: 0.3;
            }

            .alice-carousel__dots-item.__active {
                background: #FFFFFF;
                opacity: 1;
            }

            .slick-slider {
                width: 100%;
            }

            .slick-dots li.slick-active button::before {
                color: #FFFFFF;
            }
        }

        .banner-slider.slide-mobile {
            height: 200px;
        }

        .banner-slider.slick-slider {
            width: 55%;
            margin: auto;
            margin-top: 30px;
        }

        .btn-prev {
            font-size: 50px;
            color: #FFFFFF;
            cursor: pointer;
        }

        .btn-prev.is-mobile {
            margin-top: -70px;
            margin-left: -20px;
            margin-right: 20px;
        }

        .btn-next {
            font-size: 50px;
            color: #FFFFFF;
            cursor: pointer;
        }

        .btn-next.is-mobile {
            margin-top: -70px;
        }

        @media all and (max-width: '450px') {
            .alice-carousel {
                left: -15px;
            }
        }
    }

    .content-layanan {
        margin-top: 10px;
        padding: 0px 20px 0px 20px;

        .icon-col {
            padding: 25px 10px;
        }

        .icon-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .icon-label {
            text-align: center;
            color: $app-color__text--dark;
            padding-top: 10px;
        }

        .rw-body {
            border: 1px solid #DDDDDD;
            border-radius: 10px;
            align-items: center;
            padding: 10px;
            width: 100%;
            background: #F6F8FB;
            margin-bottom: 1rem;
        }

        .card {
            box-shadow: 0px 4px 10px rgba(16, 30, 115, 0.08);
            border-radius: 8px;
        }

        .card-footer {
            background-color: #FFFFFF;
            border-top: 1px solid #E8E8E8;
        }
    }

    .icon-48 {
        width: 48px;
        height: 48px;
    }

    .label-text {
        font-size: 16px;
    }

    .txt-48 {
        font-size: 48px;
    }

    .txt-24 {
        font-size: 24px;
    }

    .txt-18 {
        font-size: 28px;
    }

    .txt-16 {
        font-size: 16px;
    }

    .txt-14 {
        font-size: 14px;
    }

    .pd-10 {
        padding: 10px;
    }

    .img-redirrect {
        max-height: 100%;
        bottom: 0;
        left: 0;
        margin: auto;
        overflow: auto;
        position: fixed;
        right: 0;
        top: 0;
        background: url("/img/info-rebranding.jpg") no-repeat center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .img-redirrect-mobile {
        bottom: 0;
        left: 0;
        margin: auto;
        overflow: auto;
        position: fixed;
        right: 0;
        top: 0;
        background: url("/img/info-rebranding-mobile.jpg") no-repeat center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .img-fit-contain {
        object-fit: contain;
    }
}