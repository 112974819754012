@import '../../../Style.scss';

.radius {
  border-radius: 10px;
}

.reg-modal {
  height: 771px;
}

.pln {
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card-saldo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    margin-top: 20px;
    height: 48px;
    padding: 10px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0px;
  z-index: 1;
  background-color: #FFFFFF;

  .title {
    color: $app-color__text--dark;
    font-size: 24px;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: bold;
    text-align: center;
  }
}

.body {
  border-bottom: 0px;

  .row {
      padding: 5px;
  }

  .group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .label {
    font-size: 16px;
  }

  .label-info {
    position: static;
    width: 468px;
    height: 59px;
    left: 0px;
    top: 0px;
    
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .label-bold {
    font-weight: bold;
    color: $app-color__text--dark;
  }

  .info {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .link {
    color: $app-color__primary;
    text-decoration: underline;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: bold;
    cursor: pointer;
  }

  .close-modal {
    cursor: pointer;
  }

  .medium {
    font-size: 14px;
  }

  .small {
    font-size: 10px;
  }

  .lupa-pin-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 36px;

    position: static;
    width: 320px;
    height: 52px;
    right: 110px;
    top: 325px;

    /* background: #FFFFFF; */
    border: 1px solid #8E8E8E;
    box-sizing: border-box;
    border-radius: 500px;
    box-shadow: none;
    transition: none;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #8E8E8E;
    text-transform: none;
    background: none;
  }

  .inq-body-last {
    border-bottom: 1px solid #E8E8E8;
  }

  .inq-end-label {
      text-align: end;
  }

  .inq-balance {
      border: 2px solid $app-color__primary;
      border-radius: 100px;
      color: $app-color__primary;
  }
}

.button-modal {
  box-shadow: none;
  transition: none;

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  background: none;
  background-image: linear-gradient(#FF9F00, #FF7011);
}

.button-m-outline {
  background: transparent;
  border: $app-color__secondary 2px solid;
  border-color: $app-color__secondary;
  color: $app-color__secondary;
}

.label-title {
  font-weight: bold;
  font-size: 18px;
}

.label-bold {
  font-weight: bold;
  font-size: 16px;
}

.label-bold.end {
  text-align: end;
}

.placeholder-text {
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.15em;
}

.bold {
  font-weight: bold;
}

.color-primary {
  color: $app-color__primary
}

.btn:hover {
  color: #fff !important;
  text-decoration: none;
}

.mg-top-10 {
  margin-top: 10px;
}

.pd-top-10 {
  padding-top: 10px;
}

.pd-top-20 {
  padding-top: 20px;
}

.pd-top-30 {
  padding-top: 30px;
}

.pd-right {
  padding-right: 10px;
}

.text-end {
    text-align: end;
}

.form-pelanggan {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid #E8E8E8;

  .form-icon {
      width: 18px;
      height: 18px;
      color: $app-color__text;
  }

  .form-icon-btn {
      width: 36px;
      height: 36px;
      color: $app-color__text;
  }

  .form-label, .form-label-main {
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
  }

  .form-label-ex{
      margin-left: 0.2em;
  }   

  .form-label.start {
      align-self: start;
  } 
}